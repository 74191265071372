<template>
  <b-container v-if="getPurchaseRequest" v-can="'purchase.request.view'">
    <b-row>
      <b-col lg="4" class="profile-center">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title m-3">Detalles De la Solicitud de Compra</h4>
          </template>
          <template v-slot:body>
            <b-row align-v="center">
              <b-form-group class="col-md-12">
                <h6 class="custom-text">CORRELATIVO:</h6>
                <h5>{{ getPurchaseRequest.correlative }}</h5>
              </b-form-group>
              <b-form-group class="col-md-6">
                <h6 class="custom-text">FECHA:</h6>
                <h5>{{ getPurchaseRequest.created_at }}</h5>
              </b-form-group>
              <b-form-group class="col-md-6">
                <h6 class="custom-text">ÁREA:</h6>
                <h5>{{ getPurchaseRequest.area.name }}</h5>
              </b-form-group>
              <b-form-group class="col-md-6">
                <h6 class="custom-text">ESTADO:</h6>
                <h5>{{ getPurchaseRequest.status_description }}</h5>
              </b-form-group>
              <b-form-group class="col-md-6">
                <h6 class="custom-text">SOLICITANTE:</h6>
                <h5>{{ getPurchaseRequest.user_applicant.fullname }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text">DESCRIPCIÓN:</h6>
                <h5>{{ getPurchaseRequest.description }}</h5>
              </b-form-group>
              <b-form-group
                class="col-md-12 mt-1 text-center"
                v-if="getPurchaseRequest.status === 6"
              >
                <b-button
                  variant="info"
                  class="shadow"
                  block
                  @click="resendMail(getPurchaseRequest.id)"
                  ><i class="ri-refresh-line"></i>Reenviar correo de Aprobación
                </b-button>
              </b-form-group>
              <b-form-group
                class="col-md-12 mt-1 text-center"
                v-if="getPurchaseRequest.status === 6"
                v-can="'purchase.request.approved'"
              >
                <b-button
                  variant="none"
                  class="shadow iq-bg-primary"
                  block
                  @click="$refs.convert.showModal(getPurchaseRequest)"
                  ><i class="ri-git-merge-line"></i>Convertir a Orden de Compra
                </b-button>
              </b-form-group>
              <b-form-group
                v-if="
                  getPurchaseRequest.status === 1 ||
                  getPurchaseRequest.status === 4
                "
                class="col-md-12 mt-1 text-center"
              >
                <b-button
                  variant="success"
                  block
                  @click="
                    approved({ id: getPurchaseRequest.id, type: 2, status: 6 })
                  "
                  ><i class="ri-mail-send-line"></i>Enviar para aprobación
                </b-button>
              </b-form-group>
              <b-form-group
                class="col-md-12 mt-1 text-center"
                v-if="getPurchaseRequest.status === 6"
                v-can="'purchase.request.rejected'"
              >
                <b-button
                  variant="none"
                  class="shadow iq-bg-danger"
                  block
                  @click="changeStatus(getPurchaseRequest.id)"
                  ><i class="ri-close-circle-line mt-1"> </i>Rechazar Solicitud
                  de Compra
                </b-button>
              </b-form-group>
              <b-form-group
                class="col-md-12 mt-1 text-center"
                v-if="
                  getPurchaseRequest.status === 1 ||
                  getPurchaseRequest.status === 4
                "
              >
                <b-button
                  variant="danger"
                  block
                  @click="deleteRequest($route.params.id)"
                  ><i class="ri-delete-bin-line"></i>Eliminar Solicitud de
                  Compra
                </b-button>
              </b-form-group>
              <b-form-group class="col-md-6 mt-1 text-center">
                <b-button
                  variant="primary"
                  block
                  @click="$refs.comentaries.show('sc', $route.params.id)"
                  ><i class="ri-chat-new-line"></i>Agregar comentarios
                </b-button>
              </b-form-group>
              <b-form-group class="col-md-6 mt-1 text-center">
                <b-button
                  variant="dark"
                  block
                  @click="$refs.list.show('sc', $route.params.id)"
                  ><i class="ri-chat-smile-3-line mt-1"> </i>Ver comentarios
                </b-button>
              </b-form-group>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <order-quotations v-on:loadData="loadData" type="request" />
      </b-col>
    </b-row>
    <list ref="list" />
    <Comentaries ref="comentaries" />
    <convert ref="convert" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import list from "@/components/core/modals/ListComentaries.vue";
import Comentaries from "@/components/core/modals/Comentaries.vue";
import convert from "@/components/core/modals/ConvertPurchaseToOrders.vue";
import OrderQuotations from "@/components/core/orderQuotations/QuotationsList.vue";

export default {
  name: "Show",
  components: {
    list,
    Comentaries,
    convert,
    OrderQuotations,
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.loadData();
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          html: "Solicitudes de Compras",
          to: "/purchase_requests/list",
        },
        {
          text: "Detalles Solicitud de Compra",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      showPurchaseRequest: "purchase/showPurchaseRequest",
      approved: "purchase/approvedPurchaseRequest",
      resendMail: "purchase/resendMailPurchase",
      destroyPurchaseRequests: "purchase/destroyPurchaseRequests",
    }),
    async loadData() {
      await this.showPurchaseRequest(this.$route.params.id);
    },
    async changeStatus(payload) {
      try {
        this.isBusy = true;
        const res = await this.$store.dispatch(
          "purchase/changeStatusPurchaseRequest",
          { id: payload, status: 5 }
        );

        core.showSnackbar("success", res.message);
        await this.$store.dispatch("quotations/listQuotationsInPurchase", {
          type: "request",
          id: this.$route.params.id,
        });
        await this.showPurchaseRequest(this.$route.params.id);
        this.isBusy = false;
      } catch (error) {
        console.log(error);
        this.isBusy = false;
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    async storePurchaseOrders(data) {
      try {
        this.isBusy = true;
        const payload = {
          type: 2,
          area_id: data.area.id,
          purchase_requests: [{ id: data.id }],
        };
        const res = await this.$store.dispatch(
          "orders/storePurchaseOrders",
          payload
        );
        core.showSnackbar("success", "Orden de Compra agregada Correctamente");
        this.$router.replace(`/orders_requests/show/${res.id}`);
        this.isBusy = false;
      } catch (error) {
        console.log(error);
        this.isBusy = false;
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    deleteRequest(item) {
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          try {
            if (value) {
              this.destroyPurchaseRequests(item);
              this.$router.replace("/purchase_requests/list");
              core.showSnackbar("success", "Solicitud eliminada correctamente");
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters({
      getPurchaseRequest: "purchase/getPurchaseRequest",
    }),
  },
};
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
