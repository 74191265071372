<template>
  <div>
    <b-modal id="bv-modal-purchase-to-orders" hide-footer>
      <template #modal-title>
        Convertir solicitudes de compras en ordenes de compras
      </template>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- PROVIDERS -->
            <b-form-group
              class="col-md-12"
              label-for="Proveedor"
              label="Proveedor"
            >
              <ValidationProvider
                name="Proveedor"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :filterable="false"
                  v-model="form.provider_id"
                  placeholder="Seleccionar..."
                  :options="providersOptions"
                  :reduce="(label) => label.value"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #no-options="{}"> Sin Resultados </template>
                </v-select>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <!-- TERMS AND CONDITIONS -->
            <b-form-group
              class="col-md-12"
              label-for="Terminos y condiciones"
              label="Terminos y condiciones"
            >
              <ValidationProvider
                name="Terminos y condiciones"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                  v-model="form.terms"
                  placeholder="Ingresar terminos y condiciones"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button variant="primary" block type="submit">Guardar</b-button>
        </form>
      </ValidationObserver>
    </b-modal>
    <div></div>
  </div>
</template>
 <script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  async mounted() {
    core.index();
    await this.fechProviders();
  },
  data() {
    return {
      form: {
        type: 2,
        area_id: "",
        purchase_requests: "",
        description: "",
        provider_id: "",
        terms: "",
      },
    };
  },
  methods: {
    ...mapActions({
      storeArea: "area/storeArea",
      fechProviders: "providers/fechProviders",
      convertPurchase: "orders/storePurchaseOrders",
    }),
    showModal(item) {
      if (item) {
        (this.form.area_id = item.area.id),
          (this.form.description = item.description);
        this.form.purchase_requests = [{ id: item.id }];
      }
      this.$bvModal.show("bv-modal-purchase-to-orders");
    },
    async onSubmit() {
      try {
        const res = await this.convertPurchase(this.form);
        core.showSnackbar("success", "Orden de Compra agregada Correctamente");
        this.$bvModal.hide("bv-modal-purchase-to-orders");
        this.$router.replace(`/orders_requests/show/${res.id}`);
      } catch (error) {
        console.log(error);
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getProviders: "providers/getProviders",
    }),
    providersOptions() {
      if (this.getProviders) {
        return this.getProviders.map(({ id: value, business_name: label }) => ({
          value,
          label,
        }));
      }
    },
  },
};
</script>